<template>
  <div class="article">
    <div class="container">
      <div class="content">
        <header>
          <div class="title">
            <h2>{{ detail.title }}</h2>
          </div>
        </header>
        <article class="detail" v-html="detail.content"></article>
      </div>
    </div>
  </div>

</template>

<script>
import {newsDetail} from "@/api";

export default {
  name: "Article",
  data() {
    return {
      detail: {}
    }
  }
  , mounted() {
    // const articleId = this.$route.query.id;
    // const title = this.$route.query.title;
    // const content = this.$route.query.content;
    console.log(this.$route.query)
    this.detail.title = this.$route.query.title;
    this.detail.content = this.$route.query.content;
    // console.log("create articleId",articleId,title,content);
  }
}
</script>


<style scoped lang="scss">
.article {
  background-color: #f7f5f5;
  padding: 40px 0;
}

.content {
  padding: 40px;
  background-color: #fff;
}

header {
  padding-bottom: 40px;
  border-bottom: 1px solid #f7f5f5;

  & .title {
    & h2 {
      margin: 0;
      font-size: 30px;
      line-height: 42px;
      font-weight: 700;
      color: #333;
    }
  }
}

.detail {
  padding-top: 40px;
  font-size: 16px;
  color: #333;
  margin: 0 0 20px;
  line-height: 2;
  font-weight: 400;
  text-align: justify;
  text-indent: 2rem;

  & > h1 {
    font-size: 24px;
    line-height: 24px;
    margin-top: 30px;
    margin-bottom: 20px;
  }

  & > img {
    display: block;
    max-width: 100%;
    margin: 40px auto;
  }

  & > p {
    color: #333;
    margin: 0 0 20px;
    font-size: 16px;
    line-height: 2;
    font-weight: 400;
    text-align: justify;
    text-indent: 2rem;
  }
}

</style>